<template>
  <div class="text-center">
    <div class="mx-auto tree" id="visualization"></div>
  </div>
</template>

<style scoped>
.tree {
  height: calc(100vh - 120px);
  max-width: 70vw;
}
</style>

<script>
import { mapState } from "vuex";
import { FoamTree } from "@carrotsearch/foamtree";

function parser_tree(rParserEtools, reponse_carrot) {
  let totalClusters = reponse_carrot["clusters"].length;

  function carreauSecondaire(documents) {
    let carreauSecondaire = [];
    documents.forEach((element) => {
      carreauSecondaire.push({
        label: rParserEtools["response"].mergedRecords[element].title,
      });
    });
    return carreauSecondaire;
  }

  let carreauPincipal = [];
  reponse_carrot["clusters"].forEach((clusters) => {
    let listeCarreauSecondaire = carreauSecondaire(clusters["documents"]);
    carreauPincipal.push({
      label: clusters["labels"][0] + ` (${listeCarreauSecondaire.length})`,
      weight: 1 / (totalClusters / listeCarreauSecondaire.length),
      groups: listeCarreauSecondaire,
    });
  });

  return carreauPincipal;
}

export default {
  name: "tree",
  computed: {
    ...mapState(["rParserEtools", "reponse_carrot"]),
  },
  watch: {
    rParserEtools: function () {
      this.startFoamTree();
    },
  },
  methods: {
    startFoamTree: function () {
      new FoamTree({
        id: "visualization",
        dataObject: {
          groups: parser_tree(this.rParserEtools, this.reponse_carrot),
        },
      });
    },
  },
  mounted: function () {
    this.startFoamTree();
  },
};
</script>
